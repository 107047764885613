
import VImage from '@@/common/components/ui/images/VImage';
import ContentBlockDescription
    from 'portal/components/common/content-card/ContentCardDescription';

export default {
    name: 'ContentCard',

    components: {
        ContentBlockDescription,
        VImage,
    },

    props: {
        id: {
            type: Number,
            required: true,
        },

        slug: {
            type: String,
            required: true,
        },

        size: {
            type: String,
            default: 'small',
            validator: v => [
                'extra-small',
                'small',
                'pre-medium',
                'medium',
                'pre-large',
                'medium-large',
                'large',
                'large-no-photo',
                'pre-big',
                'big',
                'custom',
            ].includes(v),
        },

        image: {
            type: String,
            default: '',
        },

        title: {
            type: String,
            required: true,
        },

        text: {
            type: String,
            default: '',
        },

        isNew: {
            type: Boolean,
            default: false,
        },

        isExclusive: {
            type: Boolean,
            default: false,
        },

        partner: {
            type: String,
            default: '',
        },

        type: {
            type: Number,
            default: NaN,
        },

        bonusTag: {
            type: String,
            default: null,
        },

        categories: {
            type: [String, Array],
            default: () => [],
        },

        readTime: {
            type: Number,
            default: null,
        },

        pubDate: {
            type: String,
            default: null,
        },

        video: {
            type: String,
            default: null,
        },

        // пропс добавляется если этот компонент рендерится в списке последних новостей
        isLatestNews: {
            type: Boolean,
            default: false,
        },

        /**
         * Определяет отображение подложки для карточки
         */
        hasCover: {
            type: Boolean,
            default: false,
        },

        /**
         * Определяем какие изображения использовать
         */
        nonLazyImage: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        classList() {
            return {
                [this.$style[`_${this.size}`]]: this.size,
                [this.$style._cover]: this.hasCover,
                [this.$style['_isLatestNews']]: this.isLatestNews,
            };
        },

        currentLink() {
            return this.video ? this.video : `/media/${this.id}/${this.slug}`;
        },

        linkTag() {
            return this.video ? 'a' : 'n-link';
        },

        linkAttrs() {
            return this.linkTag === 'n-link' ?
                { to: this.currentLink } :
                { href: this.currentLink, target: '_blank' };
        },

        lowSizeImage() {
            return [this.size === 'small', this.size === 'pre-medium', this.size === 'medium'].some(Boolean);
        },

        imgWidth() {
            return this.lowSizeImage ? 200 : 800;
        },

        altString() {
            return `${this.title.replace(/<\/?[^>]+(>|$)/g, '')}_${this.id}`;
        },

        computedSizesString() {
            return this.lowSizeImage ? 'mobile:100vw tablet:400px laptop:400px desktop:400px' : 'mobile:100vw tablet:800px laptop:800px desktop:800px';
        },
    },

};
