
// Vuex
import { mapActions, mapGetters } from 'vuex';
// Utils
import { formatDateTime } from '@@/common/assets/js/utils/date-time-utils';
// Components
import ContentCard from '../content-card/ContentCard.vue';
import VScrollBox from 'portal/components/ui/scrollbox/VScrollBox.vue';

// Блоки яндекс рекламы
const YANDEX_ADS = [
    // {
    //     id: 'R-A-12512853-1',
    //     type: 'yandexAd',
    //     placeIndex: 5,
    //     adsId: 'R-A-12512853-1',
    // },
    // {
    //     id: 'R-A-12512853-6',
    //     type: 'yandexAd',
    //     placeIndex: 11,
    //     adsId: 'R-A-12512853-6',
    // },
];

const LIMITED_HEIGHT_GAP = 270;
const NEWS_TITLE = 'Последние новости';
const FEED_LABEL = 'Лента';
const COMPANIES_LABEL = 'Новости компаний';

export default {
    name: 'NewsAside',

    components: {
        VScrollBox,
        ContentCard,
        AdsCard: () => import('../ads/AdsCard.vue'),
        YandexAdBlock: () => import('../ads/YandexAdBlock.vue'),
    },

    props: {
        limitedHeight: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            title: NEWS_TITLE,
            currentTab: 0,
            tabs: [
                {
                    value: 0,
                    label: FEED_LABEL,
                },
                {
                    value: 1,
                    label: COMPANIES_LABEL,
                },
            ],
        };
    },

    computed: {
        ...mapGetters({
            newsFeed: 'latestNews/getFeedList',
            feedPagination: 'latestNews/getFeedPagination',
            newsCompany: 'latestNews/getCompaniesList',
            companiesPagination: 'latestNews/getCompaniesPagination',
            adsList: 'latestNews/getAdsList',
        }),

        isStateFeed() {
            return this.currentTab === this.tabs[0].value;
        },

        isStateCompanies() {
            return this.currentTab === this.tabs[1].value;
        },

        currentStateNews() {
            const news = this.isStateFeed ? this.newsFeed : this.newsCompany;
            const newsWithAds = JSON.parse(JSON.stringify(news));

            this.adsList.forEach(item => {
                if (item.order_in_news <= 6) {
                    item.nonLazyImage = true;
                }

                if (item?.order_in_news <= news.length && item.image && item.link) {
                    newsWithAds.splice(item?.order_in_news, 0, item);
                }
            });

            // Добавляем блоки Яндекс рекламы
            YANDEX_ADS.forEach(item => {
                if (newsWithAds.length >= item.placeIndex) {
                    newsWithAds.splice(item.placeIndex, 0, item);
                }
            });

            return newsWithAds;
        },

        currentStatePagination() {
            return this.isStateFeed ? this.feedPagination : this.companiesPagination;
        },
    },

    watch: {
        $route() {
            this.resetData();
        },
    },

    methods: {
        ...mapActions({
            fetchFeed: 'latestNews/fetchFeed',
            fetchMoreFeed: 'latestNews/fetchMoreFeed',
            resetData: 'latestNews/resetData',
        }),

        getItemComponent(item) {
            if (item.type === 'yandexAd') {
                return 'YandexAdBlock';
            }

            return item.order_in_news ? 'AdsCard' : 'ContentCard';
        },

        loadMoreNews() {
            if (this.currentStatePagination.isLoad) {
                return;
            }

            if (this.limitedHeight &&
                this.$refs.component &&
                this.$refs.wrapper &&
                this.$refs.wrapper.clientHeight + LIMITED_HEIGHT_GAP >= this.$refs.component.clientHeight) {
                return;
            }

            this.fetchMoreFeed();
        },

        getNewsPubDate(pub_date) {
            if (!pub_date) {
                return;
            }

            const pubDate = new Date(pub_date);
            const now = new Date();
            const today = formatDateTime(pubDate, '$j $b $y') === formatDateTime(now, '$j $b $y');
            const yesterday = formatDateTime(pubDate, '$j $b $y') === formatDateTime(new Date(now.getTime() - 24 * 60 * 60 * 1000), '$j $b $y');
            const time = formatDateTime(pubDate, '$H:$I');

            if (today) {
                const minutesAgo = Math.floor((now - pubDate) / 60000);
                if (minutesAgo < 60) {
                    return `${minutesAgo} минут назад`;
                } else {
                    return time;
                }
            } else if (yesterday) {
                return formatDateTime(pubDate, '$j $b, $H:$I');
            } else {
                return formatDateTime(pubDate, '$j $b, $H:$I');
            }
        },
    },
};
