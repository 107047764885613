// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchBlock_5OpKU{width:100%}.form_xtyi1{display:flex;gap:.8rem}.wrapper_yBsFI{border-radius:1rem;margin-top:1.6rem;overflow:hidden}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "56px",
	"footer-h": "61.2rem",
	"mobile": "0",
	"tablet-sm": "768px",
	"tablet": "1024px",
	"laptop": "1280px",
	"desktop": "1440px",
	"large-desktop": "1920px",
	"SearchBlock": "SearchBlock_5OpKU",
	"form": "form_xtyi1",
	"wrapper": "wrapper_yBsFI"
};
module.exports = ___CSS_LOADER_EXPORT___;
