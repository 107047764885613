// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TagList_tI6NN{display:flex;font-size:1.4rem;font-weight:400;letter-spacing:-.056rem;line-height:1.68rem;margin-bottom:1.2rem;position:relative;z-index:3}.TagList_tI6NN li:not(:last-child):after{background-color:#abaebb;border-radius:50%;content:\"\";display:block;height:.4rem;margin:0 .8rem;width:.4rem}.TagList_tI6NN li{align-items:center;display:flex}.TagList_tI6NN._singleColor_qLZ7w .category_UtQIn{color:#73788c}.TagList_tI6NN._medium_m0YV6,.TagList_tI6NN._pre-medium_6ijYB,.TagList_tI6NN._small_IiaCW{font-size:1.2rem;font-weight:400;letter-spacing:-.048rem;line-height:1.44rem;margin-bottom:.8rem}.TagList_tI6NN._medium-large_CXIuH li:not(:last-child):after{margin:0 .6rem}@media(max-width:767.98px){.TagList_tI6NN{font-size:1.2rem;font-weight:400;letter-spacing:-.048rem;line-height:1.44rem}}.specialTag_zgT1B{color:#ea4e3d;font-weight:500}.specialTag_zgT1B svg{min-width:1.2rem}.icon_QBUyu{margin-right:.4rem}.tag_i6q58{color:#73788c;padding-right:2px}.tag_i6q58:last-child{display:block;overflow:hidden;text-overflow:ellipsis}.tag_i6q58:last-child,.tag_i6q58:not(:last-child){white-space:nowrap}.tag_i6q58 .icon_QBUyu{color:#abaebb}.tag_i6q58 svg{min-width:1.2rem}.tag_i6q58._darkColor_qoNTg{color:#17181c}.tag_i6q58._readTime_\\+qIHM:last-child{display:flex}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "56px",
	"footer-h": "61.2rem",
	"mobile": "0",
	"tablet-sm": "768px",
	"tablet": "1024px",
	"laptop": "1280px",
	"desktop": "1440px",
	"large-desktop": "1920px",
	"TagList": "TagList_tI6NN",
	"_singleColor": "_singleColor_qLZ7w",
	"category": "category_UtQIn",
	"_medium": "_medium_m0YV6",
	"_pre-medium": "_pre-medium_6ijYB",
	"_small": "_small_IiaCW",
	"_medium-large": "_medium-large_CXIuH",
	"specialTag": "specialTag_zgT1B",
	"icon": "icon_QBUyu",
	"tag": "tag_i6q58",
	"_darkColor": "_darkColor_qoNTg",
	"_readTime": "_readTime_+qIHM"
};
module.exports = ___CSS_LOADER_EXPORT___;
