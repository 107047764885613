// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NewsAside_ptH7C{height:100%;width:32.5rem}.NewsAside_ptH7C,.wrapper_BJxbN{overflow:hidden;position:relative;transition:.3s ease}.wrapper_BJxbN{background-color:#fff;border-radius:2rem;display:flex;flex-direction:column;max-height:100%;padding:2.4rem;width:100%}@media(max-width:1023.98px){.wrapper_BJxbN{padding:1.2rem 2rem}}.header_bFmoQ{display:flex;flex-direction:column}@media(max-width:1023.98px){.header_bFmoQ{margin-bottom:.8rem;margin-top:.5rem}}.title_mznt\\+{font-size:2rem;font-weight:500;letter-spacing:-.08rem;line-height:2.4rem}.tabs_FPaQK{background-color:#f1f1f4;border-radius:1rem;display:flex;margin-top:1.6rem;padding:.4rem}@media(max-width:1023.98px){.tabs_FPaQK{margin-top:2.1rem}}.button_nwDI8{border-radius:.8rem;flex:1 1 auto}@media(-ms-high-contrast:active),(-ms-high-contrast:none),(hover){.button_nwDI8:hover .icon_USeLm,.button_nwDI8:hover .label_hoKC0{color:#855aff}}.label_hoKC0{font-size:1.4rem;font-weight:500;letter-spacing:-.056rem;line-height:1.68rem;text-transform:none}.list_ibWcK{display:flex;flex-direction:column}.item_0zbeT{margin-top:2.4rem}.preloader_yHKck{bottom:1rem;left:50%;position:absolute;transform:translateX(-50%)}.intersect_NbzGA._top_o3PJw{left:0;position:absolute;top:0}.intersect_NbzGA._bottom_ILDUC{transform:translateY(-1px)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "56px",
	"footer-h": "61.2rem",
	"mobile": "0",
	"tablet-sm": "768px",
	"tablet": "1024px",
	"laptop": "1280px",
	"desktop": "1440px",
	"large-desktop": "1920px",
	"NewsAside": "NewsAside_ptH7C",
	"wrapper": "wrapper_BJxbN",
	"header": "header_bFmoQ",
	"title": "title_mznt+",
	"tabs": "tabs_FPaQK",
	"button": "button_nwDI8",
	"icon": "icon_USeLm",
	"label": "label_hoKC0",
	"list": "list_ibWcK",
	"item": "item_0zbeT",
	"preloader": "preloader_yHKck",
	"intersect": "intersect_NbzGA",
	"_top": "_top_o3PJw",
	"_bottom": "_bottom_ILDUC"
};
module.exports = ___CSS_LOADER_EXPORT___;
